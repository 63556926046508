<template>
    <div>
        <ViewAttendance ref="ViewAttendance" @reload="handleReloadList" />
        <Collapse class="adminCollapsible" simple>
            <Panel v-for="itm in tblData" :key="itm._id" :name="itm.details.name" class="collapsiblePane">
                <label>
                    <span class="profile">
                        <img :src="profileImg" />
                    </span>
                    <span class="div name">
                        <strong>{{ itm.details.name }}</strong>
                        <span>{{ projectRole.find((x) => x.value === itm.details.mainRole).name }}</span>
                    </span>
                    <span class="vl"></span>
                    <span class="div regular">
                        <strong>Regular</strong>
                        <span>{{ itm.totalReg }}</span>
                    </span>
                    <span class="div">
                        <strong>Absent</strong>
                        <span>{{ itm.totalAbsent }}</span>
                    </span>
                    <span class="div">
                        <strong>Undertime</strong>
                        <span>{{ itm.totalUndertime }}</span>
                    </span>
                    <span class="div">
                        <strong>Late</strong>
                        <span>{{ itm.totalLate }}</span>
                    </span>
                    <span class="div">
                        <strong>Disputes</strong>
                        <span>{{ itm.totalDisputes }}</span>
                    </span>
                    <span class="div">
                        <strong>Special Non-working</strong>
                        <span>{{ itm.totalSNONWHoliday }}</span>
                    </span>
                    <span class="div">
                        <strong>Special working</strong>
                        <span>{{ itm.totalSWHoliday }}</span>
                    </span>
                    <span class="div">
                        <strong>Regular Holiday</strong>
                        <span>{{ itm.totalRegHoliday }}</span>
                    </span>
                </label>
                <template #content>
                    <Table
                        :columns="tblColumns"
                        :data="itm.elem"
                        size="small"
                        :loading="isLoading"
                        class="adminAttendanceList"
                    />
                </template>
            </Panel>
        </Collapse>
        <Page
            :total="totalCount"
            :current="currentPage"
            :page-size="15"
            class="pagination"
            @on-change="handleOnpageChanged"
        />
    </div>
</template>

<script>
import moment from 'moment';
import ViewAttendance from '@/components/Attendance/Dev/ViewAttendance';
import Smile from '@/assets/images/smile.svg';
export default {
    components: { ViewAttendance },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        attendanceLogsList: {
            type: Array,
            default: () => [],
        },
        currentPage: {
            type: Number,
            default: 0,
        },
        totalCount: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            tblColumns: [
                {
                    title: 'Date',
                    key: 'createdAt',
                    width: 200,
                    render: (h, params) => {
                        return h(
                            'div',
                            params.row.createdAt ? moment(params.row.createdAt).utc().format('YYYY-MM-DD') : ''
                        );
                    },
                },
                {
                    title: 'First Clock In',
                    key: 'clockInTime',
                    //fixed: 'left',
                    width: 200,
                    render: (h, params) => {
                        // const date = params.row.status.includes('leave') ? params.row.clockInTime : params.row.createdAt

                        if (params.row.status.toLowerCase().includes('leave|'))
                            return h('div', { class: 'hyphenFont' }, '-');

                        return ![
                            'leave|vl',
                            'leave|sl',
                            'leave|al',
                            'absent',
                            'rest',
                            'absent-halfday|AM',
                            'absent-halfday|PM',
                        ].includes(params.row.status)
                            ? h('div', moment(params.row.clockInTime).utc().format('hh:mm A'))
                            : h('div', { class: 'hyphenFont' }, '-');

                        // return params.row.clockInTime
                        //     ? h('div', moment(params.row.clockInTime).utc().format('hh:mm A'))
                        //     : h('div', { class: 'hyphenFont' }, '-');
                    },
                },
                {
                    title: 'Last Clock Out',
                    key: 'clockOutTime',
                    width: 200,
                    render: (h, params) => {
                        // return params.row.clockOutTime
                        //     ? h('div', moment(params.row.clockOutTime).utc().format('hh:mm A'))
                        //     : h('div', { class: 'hyphenFont' }, '-');
                        if (params.row.status.toLowerCase().includes('leave|'))
                            return h('div', { class: 'hyphenFont' }, '-');

                        if (!params.row.clockOutTime) {
                            return h('div', { class: 'hyphenFont' }, '-');
                        }

                        return ![
                            'leave|vl',
                            'leave|sl',
                            'leave|al',
                            'absent',
                            'rest',
                            'absent-halfday|AM',
                            'absent-halfday|PM',
                        ].includes(params.row.status)
                            ? h('div', moment(params.row.clockOutTime).utc().format('hh:mm A'))
                            : h('div', { class: 'hyphenFont' }, '-');
                    },
                },
                {
                    title: 'Total Hours',
                    key: 'totalDailyHrs',
                    width: 200,
                    render: (h, params) => {
                        return params.row.totalDailyHrs
                            ? h('div', Number(params.row.totalDailyHrs).toFixed(0) + ' hours')
                            : h('div', { class: 'hyphenFont' }, '-');
                    },
                },
                {
                    title: 'Status',
                    key: 'status',
                    minWidth: 250,
                    render: (h, params) => {
                        // return h('div', params.row.status);
                        const status = params.row.status;
                        let label = '',
                            className = 'statusLabel';
                        const upperCaseStats = status.toString().toUpperCase();
                        switch (status.toString().trim()) {
                            case 'regular':
                                label = upperCaseStats;
                                className = `${className} success`;
                                break;
                            case 'snonworkholiday':
                                label = 'SPECIAL NON-WORKING HOLIDAY';
                                className = `${className} success`;
                                break;
                            case 'sworkholiday':
                                label = 'SPECIAL WORKING HOLIDAY';
                                className = `${className} success`;
                                break;
                            case 'regholiday':
                                label = 'REGULAR HOLIDAY';
                                className = `${className} secondary`;
                                break;
                            case 'rest':
                                label = 'REST DAY';
                                className = `${className} secondary`;
                                break;
                            case 'absent':
                                label = upperCaseStats;
                                className = `${className} danger`;
                                break;
                            case 'late':
                                label = upperCaseStats;
                                className = `${className} warning`;
                                break;
                            case 'leave':
                                label = `ON ${upperCaseStats}`;
                                className = `${className} primary`;
                                break;
                            case 'leave|vl':
                                label = `ON ${upperCaseStats}`;
                                className = `${className} primary`;
                                break;
                            case 'leave|sl':
                                label = `ON ${upperCaseStats}`;
                                className = `${className} primary`;
                                break;
                            case 'leave|al':
                                label = `ON ${upperCaseStats}`;
                                className = `${className} primary`;
                                break;
                            case 'leave|vl|FULL':
                                label = `ON ${upperCaseStats}`;
                                className = `${className} primary`;
                                break;
                            case 'leave|vl|AM':
                                label = `ON ${upperCaseStats}`;
                                className = `${className} primary`;
                                break;
                            case 'leave|vl|PM':
                                label = `ON ${upperCaseStats}`;
                                className = `${className} primary`;
                                break;
                            case 'leave|sl|FULL':
                                label = `ON ${upperCaseStats}`;
                                className = `${className} primary`;
                                break;
                            case 'leave|sl|AM':
                                label = `ON ${upperCaseStats}`;
                                className = `${className} primary`;
                                break;
                            case 'leave|sl|PM':
                                label = `ON ${upperCaseStats}`;
                                className = `${className} primary`;
                                break;
                            case 'leave|al|FULL':
                                label = `ON ${upperCaseStats}`;
                                className = `${className} primary`;
                                break;
                            case 'regular-halfday|AM':
                                label = upperCaseStats;
                                className = `${className} success`;
                                break;
                            case 'regular-halfday|PM':
                                label = upperCaseStats;
                                className = `${className} success`;
                                break;
                            case 'absent-halfday|AM':
                                label = upperCaseStats;
                                className = `${className} danger`;
                                break;
                            case 'absent-halfday|PM':
                                label = upperCaseStats;
                                className = `${className} danger`;
                                break;
                            case 'undertime':
                                label = upperCaseStats;
                                className = `${className} warning`;
                                break;
                            case 'undertime-halfday|AM':
                                label = upperCaseStats;
                                className = `${className} warning`;
                                break;
                            case 'undertime-halfday|PM':
                                label = upperCaseStats;
                                className = `${className} warning`;
                                break;
                            case 'timedispute':
                                label = 'TIME DISPUTE';
                                className = `${className} warning`;
                                break;
                            default:
                                break;
                        }
                        return h(
                            'div',
                            {
                                class: `${className}`,
                            },
                            label
                        );
                    },
                },
                {
                    title: 'Actions',
                    width: 200,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('Tooltip', { props: { placement: 'top', content: 'View' } }, [
                                h(
                                    'Button',
                                    { class: 'pt-btn-3' },
                                    [
                                        h('Icon', {
                                            props: {
                                                type: 'md-eye',
                                                size: '25',
                                            },
                                            on: {
                                                click: () => {
                                                    this.$refs.ViewAttendance.openModal(params.row);
                                                    console.log(params.row);
                                                },
                                            },
                                        }),
                                    ],
                                    'view'
                                ),
                            ]),
                        ]);
                    },
                },
            ],
            tblData: [],
            profileImg: Smile,
        };
    },

    watch: {
        attendanceLogsList(data) {
            this.tblData = data;
        },
    },
    methods: {
        handleReloadList() {
            this.$emit('reload');
        },
        handleOnpageChanged(page) {
            this.$emit('on-page-change', page);
        },
    },
};
</script>

<style lang="scss">
ul.pagination.ivu-page {
    text-align: end;
}
.adminAttendanceList button.pt-btn-3 {
    width: 25px;
}
.adminAttendanceList i.ivu-icon {
    margin-left: -12.5px;
}
.statusLabel {
    display: inline-block;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
    vertical-align: middle;
    max-width: 250px;
    padding: 5px;
    text-align: center;
}
.success {
    background: #49e97e;
    font-weight: bold;
}
.warning {
    background: #ffc107;
    font-weight: bold;
}
.danger {
    background: #e9495d;
}
.primary {
    background: #211841;
}
.secondary {
    background: #828899;
}

.adminCollapsible {
    .collapsiblePane {
        margin-bottom: 15px;
        border-collapse: separate;
        border-spacing: 0 5px;
        .ivu-collapse-header {
            padding-top: 15px;
            line-height: 20px;
            height: 88px;
            border: 1px solid;
            border-radius: 10px;
            label {
                strong {
                    color: #463c6e;
                }
                .name {
                    width: 185px;
                }
                .div {
                    display: inline-grid;
                    margin-right: 15px;
                    span {
                        font-size: small;
                    }
                }
                .regular {
                    margin-left: 15px;
                }
                .vl {
                    border: 1px solid grey;
                    height: 87px;
                    position: absolute;
                    top: 0;
                }
                .profile {
                    margin-right: 60px;
                    img {
                        border-radius: 50%;
                        width: 50px;
                        position: absolute;
                    }
                }
            }
        }
    }
}
</style>
